import React from 'react';
import PropTypes from 'prop-types';
import { QueryProvider } from '@thd-nucleus/data-sources';
import { ErrorBoundary } from '@thd-olt-component-react/error-boundary';

import { EProcurementHomepage } from './EProcurementHomepage';

export const EProcurementHomepageContainer = ({ slug, thdSEOBotDetection }) => {
  return (
    <ErrorBoundary name="my-homepage-content">
      <QueryProvider cacheKey="eprocurement-homepage">
        <EProcurementHomepage slug={slug} thdSEOBotDetection={thdSEOBotDetection} />
      </QueryProvider>
    </ErrorBoundary>
  );
};

EProcurementHomepageContainer.propTypes = {
  slug: PropTypes.string,
  thdSEOBotDetection: PropTypes.bool
};

EProcurementHomepageContainer.defaultProps = {
  slug: undefined,
  thdSEOBotDetection: false
};
